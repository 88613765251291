import { ComponentPropsWithoutRef, ComponentType } from 'react';

import classNames from 'classnames';

import { Star } from '../../icons/Star';

interface RatingItemsProps extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  score: number;
  maxScore?: number;
  shouldRenderInactiveItems?: boolean;
  itemClassName?: string;
  activeItemClassName?: string;
  inactiveItemClassName?: string;
  Item?: ComponentType<{ className?: string }>;
}

const RatingItems = ({
  score = 0,
  maxScore = 5,
  // NOTE: fixes build error related to type
  Item = Star as ComponentType<{ className?: string }>,
  className,
  shouldRenderInactiveItems = true,
  itemClassName = 'icon-sm',
  inactiveItemClassName = 'text-neutral-200',
  activeItemClassName = 'text-secondary-700',
}: RatingItemsProps) => {
  return (
    <div className={classNames('flex', className)}>
      {Array.from({ length: maxScore }, (_, index) => {
        const isActive = score > index;

        if (!shouldRenderInactiveItems && !isActive) {
          return null;
        }

        return (
          <Item
            key={index}
            className={classNames(
              itemClassName,
              isActive ? activeItemClassName : inactiveItemClassName,
            )}
          />
        );
      })}
    </div>
  );
};

export default RatingItems;
