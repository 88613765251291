import React, { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';
import { clamp } from 'lodash';

interface ProgressBarProps extends ComponentPropsWithoutRef<'div'> {
  min?: number;
  max?: number;
  value?: number;
  indicatorAriaLabel?: string;
  progressClassName?: string;
}

const ProgressBar = ({
  className,
  progressClassName,
  value = 0,
  min = 0,
  max = 100,
  indicatorAriaLabel,
  ...props
}: ProgressBarProps) => {
  const clampedValue = clamp(value, min, max);
  const scaleX = (100 / (max - min)) * (clampedValue - min);

  return (
    <div className={classNames('progress-bar', className)} {...props}>
      <div
        role="progressbar"
        className={classNames('progress-bar-indicator', progressClassName)}
        aria-valuenow={value}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-label={indicatorAriaLabel}
        style={{
          transform: `scaleX(${scaleX}%)`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
