'use client';

import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';

import classNames from 'classnames';

import useUniqueId from '@uikit/utils/hooks/useUniqueId';

export interface RadioButtonProps extends Omit<ComponentPropsWithoutRef<'input'>, 'type'> {
  label?: string;
  inputClassName?: string;
  labelClassName?: string;
  indicatorClassName?: string;
}

const RadioButton = (
  {
    id,
    children,
    inputClassName,
    labelClassName,
    indicatorClassName,
    className,
    label,
    ...props
  }: RadioButtonProps,
  ref: Ref<HTMLInputElement>,
) => {
  const inputId = useUniqueId(id);

  return (
    <div className={classNames('radio-button w-fit', className)}>
      <input
        className={classNames('sr-only', inputClassName)}
        id={inputId}
        type="radio"
        ref={ref}
        {...props}
      />

      <label htmlFor={inputId} className={classNames('radio-button-label', labelClassName)}>
        <div className={classNames('radio-button-indicator', indicatorClassName)}>
          <div className="radio-button-icon" />
        </div>
        {children ?? label}
      </label>
    </div>
  );
};

export default forwardRef(RadioButton);
