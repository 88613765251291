import { ComponentPropsWithoutRef, forwardRef, Ref, useMemo } from 'react';

import classNames from 'classnames';

import AngleLeft from '@uikit/icons/AngleLeft';
import AngleRight from '@uikit/icons/AngleRight';
import { getPagination } from '@uikit/utils/pagination';

export interface PaginationProps extends ComponentPropsWithoutRef<'nav'> {
  totalPages?: number;
  currentPage?: number;
  isNextButtonDisabled?: boolean;
  isPreviousButtonDisabled?: boolean;
  hideButtonsLabels?: boolean;
  onPageChange?: (page: number) => void;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
}

const Pagination = (
  {
    currentPage = 0,
    totalPages,
    isNextButtonDisabled,
    isPreviousButtonDisabled,
    hideButtonsLabels,
    onPageChange,
    onNextPage,
    onPreviousPage,
    ...props
  }: PaginationProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { pages, isLastPage, isFirstPage } = useMemo(() => {
    return getPagination({
      currentPage,
      totalPages,
    });
  }, [currentPage, totalPages]);
  const previousButtonDisabled = (!!totalPages && isFirstPage) || isPreviousButtonDisabled;
  const nextButtonDisabled = (!!totalPages && isLastPage) || isNextButtonDisabled;

  const handlePageChange = (page: number) => {
    if (!onPageChange) {
      return;
    }

    onPageChange(page);
  };

  const handleNextButtonClick = () => {
    handlePageChange(currentPage + 1);

    if (onNextPage) {
      onNextPage();
    }
  };

  const handlePreviousButtonClick = () => {
    handlePageChange(currentPage - 1);

    if (onPreviousPage) {
      onPreviousPage();
    }
  };

  return (
    <nav ref={ref} aria-label="Pagination" {...props}>
      <ul className={classNames('flex select-none list-none gap-2 p-0', 'sm:gap-3')}>
        <li>
          <button
            className={classNames(
              'sm:min-w-7 flex h-7 items-center gap-2 rounded-md px-1 text-[10px]',
              'outline-1 outline-primary transition-colors enabled:hover:text-primary',
              'disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-30',
              'group',
            )}
            onClick={handlePreviousButtonClick}
            aria-label="Go to previous page"
            disabled={previousButtonDisabled}
          >
            <span className="icon w-5 transition-transform group-active:-translate-x-1">
              <AngleLeft />
            </span>

            <span className={classNames('mr-2 hidden', !hideButtonsLabels && 'sm:inline-block')}>
              Previous
            </span>
          </button>
        </li>
        {pages.map((page, i) => {
          const isSkip = page === null;
          const isActive = currentPage === page;

          return (
            <li key={isSkip ? `skip-${i}` : page}>
              {isSkip ? (
                <div
                  className={classNames('flex h-7 w-6 items-center justify-center text-sm sm:w-7')}
                >
                  &hellip;
                </div>
              ) : (
                <button
                  aria-label={`Go to page ${page}`}
                  aria-current={isActive && 'page'}
                  className={classNames(
                    'flex h-7 w-6 items-center justify-center rounded-md text-sm outline-1 outline-primary sm:w-7',
                    'transition-all hover:bg-primary hover:bg-opacity-10 hover:text-primary',
                    isActive &&
                      'bg-primary bg-opacity-10 text-primary shadow-solid-bottom-md shadow-primary/30',
                  )}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              )}
            </li>
          );
        })}
        <li>
          <button
            className={classNames(
              'sm:min-w-7 flex h-7 items-center gap-2 rounded-md px-1 text-[10px]',
              'outline-1 outline-primary transition-colors enabled:hover:text-primary',
              'disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-30',
              'group',
            )}
            onClick={handleNextButtonClick}
            aria-label="Go to next page"
            disabled={nextButtonDisabled}
          >
            <span className={classNames('ml-2 hidden', !hideButtonsLabels && 'sm:inline-block')}>
              Next
            </span>
            <span className="icon w-5 transition-transform group-active:translate-x-1">
              <AngleRight />
            </span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default forwardRef(Pagination);
