import React, { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';

import classNames from 'classnames';

type SearchBarOptionProps<C extends ElementType> = {
  Component?: C;
  children: ReactNode;
} & ComponentPropsWithoutRef<C>;

const SearchBarOption = <C extends ElementType = 'button'>({
  className,
  Component,
  ...props
}: SearchBarOptionProps<C>) => {
  const As = Component || 'button';
  const buttonProps =
    As === 'button'
      ? {
          type: 'button',
        }
      : undefined;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return <As className={classNames('search-bar-option', className)} {...props} {...buttonProps} />;
};

export default SearchBarOption;
