import React, {
  ChangeEvent,
  ComponentPropsWithoutRef,
  FormEvent,
  forwardRef,
  Ref,
  useState,
} from 'react';

import classNames from 'classnames';

import Close from '@uikit/icons/Close';
import Search from '@uikit/icons/Search';

import { Spinner } from '../Spinner';

interface SearchBarProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'value' | 'type' | 'onChange'> {
  hasSearchIcon?: boolean;
  hasSearchButton?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  onReset?: () => void;
  isOpen?: boolean;
  isLoading?: boolean;
  inputRef?: Ref<HTMLInputElement>;
}

const SearchBar = (
  {
    className,
    value: controlledValue,
    hasSearchIcon = true,
    hasSearchButton,
    isOpen,
    onChange,
    onSearch,
    onReset,
    children,
    inputRef,
    isLoading,
    ...props
  }: SearchBarProps,
  ref: Ref<HTMLFormElement>,
) => {
  const [uncontrolledValue, setUncontrolledValue] = useState('');
  const isControlled = typeof controlledValue !== 'undefined';
  const value = isControlled ? controlledValue : uncontrolledValue;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!value?.trim()) {
      return;
    }

    if (onSearch) {
      onSearch(value);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue } = e.target;

    if (onChange) {
      onChange(targetValue);
    }

    if (!isControlled) {
      setUncontrolledValue(targetValue);
    }
  };

  const handleReset = () => {
    if (onChange) {
      onChange('');
    }

    if (onReset) {
      onReset();
    }

    if (!isControlled) {
      setUncontrolledValue('');
    }
  };

  const showOptions = isOpen ?? !!value;

  return (
    <form
      ref={ref}
      onReset={handleReset}
      onSubmit={handleSubmit}
      className={classNames('search-bar', showOptions && 'search-bar-options-active', className)}
    >
      {hasSearchIcon &&
        (isLoading ? (
          <Spinner
            className="search-bar-left-icon translate-x-1 -translate-y-px"
            color="secondary"
            size="sm"
          />
        ) : (
          <Search className="icon search-bar-left-icon" />
        ))}

      {hasSearchButton && (
        <button type="submit" className="search-bar-right-icon">
          <Search className="icon" />
        </button>
      )}

      <input
        ref={inputRef}
        type="text"
        value={value}
        className={classNames('text-field-base search-bar-input')}
        onChange={handleChange}
        {...props}
      />

      {value && (
        <button onReset={handleReset} type="reset" className="search-bar-reset-button">
          <Close className="icon search-bar-reset-button-icon" />
        </button>
      )}

      {showOptions && <div className="search-bar-options">{children}</div>}
    </form>
  );
};

export default forwardRef(SearchBar);
